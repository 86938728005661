import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-button-component',
    templateUrl: './button-component.component.html',
    styleUrls: ['./button-component.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink],
})
export class ButtonComponentComponent {
  @Input() buttonStyle: string = '';
  @Input() link: string;
  @Input() isEnable: boolean = true;
  @Input() linkImage: string;
  @Input() altImage: string;
}
